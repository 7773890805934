


















































































































import Vue from "vue";
import { LogLevels } from "@/models/log/interface";
import TeachingGroup from "@/models/teaching_group/interface";
import TeachingUnit from "@/models/teaching_unit/interface";
import { PermissionLevel } from "@/models/permissions/interface";
import TeachingUnitCard from "@/components/TeachingUnitCard.vue";
import SubscriptionNeeded from "@/components/SubscriptionNeeded.vue";
import * as firebase from "firebase/app";
export default Vue.extend({
  name: "TeachingGroup",
  components: {
    TeachingUnitCard,
    SubscriptionNeeded
  },
  props: {
    teachingGroupId: {
      type: String,
      required: true,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      teachingUnits: [],
      present: new Date(),
      // DIALOG
      dialog: false,
      title: null,
      publishingDate: null,
      hidden: false,
      freeToRegistered: null,
      lessons: [],
      documents: [],
      links: [],
      tests: [],

      //Admin mode
      adminMode: false,

      subNeededDialog: false,

      pageTitle: this.teachingGroupId == 'preparacion_fisica' ? 'Preparación física' : this.teachingGroupId,
      pageDescription: "",
      teachingGroupColor: null

    };
  },
  created() {
    this.getCategories().then(cats => {
      this.pageTitle = cats[this.teachingGroupId].name;
      this.teachingGroupColor = cats[this.teachingGroupId].color;
    })
    this.getAdmin();
    this.getUnits();
  },
  methods: {
    getUnits() {
      this.$store
            .dispatch("tests/getTeachingGroup", {
              teachingGroupId: this.teachingGroupId
            })
            .then((teachingGroup) => {
              this.teachingUnits = teachingGroup.teachingUnits;
              this.sortUnits();
              this.filterUnits();
              //console.log(this.teachingUnits);
            })
            .catch(error => {
              this.loading = false;
              this.$store.commit("showMessage", {
                text: this.$t("views/teaching_group/error_messages/error_loading"),
                color: "error"
              });
              this.$store.dispatch("log", { level: LogLevels.ERROR, message: error });
              //this.$router.push({ name: 'not_found' });
            })
            .finally(() => {
              this.loading = false;
            });
    }
    ,
    sortUnits() {
      this.teachingUnits.sort((a, b) =>
        a.updated > b.updated ? -1 : 1
      );
    },
    filterUnits() {
     this.teachingUnits = this.teachingUnits.filter(
          (tu) => !tu.hidden
        );
    },
    getAdmin() {
      firebase.firestore().collection('resources').doc('admin-users')
          .get()
          .then((doc) => {
              const found = doc.data().list.includes(this.$store.state.user.id);
              this.userIsAdmin = found;
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
    },
    async addUnit() {
      try {
        if (this.title && this.publishingDate) {
          await firebase.firestore().collection('teaching-units').add({
            title: this.title,
            publishingDate: new Date(this.publishingDate),
            updated: new Date(this.publishingDate),
            expirationDate: this.addDays(new Date(this.publishingDate), 7),
            category: this.teachingGroupId,
            hidden: this.hidden,
            freeToRegistered: this.freeToRegistered,
            lessons: this.lessons,
            documents: this.documents,
            links: this.links,
            tests: this.tests
          })

          this.getUnits();
          this.title = null;
          this.publishingDate = null;
          this.hidden = false;
          this.freeToRegistered = null;
          this.lessons = [];
          this.documents = [];
          this.links = [];
          this.tests = [];
        } else {
          this.$store.commit("showMessage", {
                text: "Completa los campos obligatorios",
                color: "error"
          });
          this.dialog = true;
        } 
      } catch (error) {
        console.log(error);
      }
    },
    addDays(date, days) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    formatDate(date: Date) {
      return date.toLocaleDateString();
    },
    getCategories() {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("resources")
        .doc("academia_categories")
        .get()
        .then(doc => {
          if (doc.exists) {
            const categories = doc.data().categories;
            resolve(categories);
          } else {
            reject("Document does not exist");
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  }
});
